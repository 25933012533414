import { useEffect, useRef } from "react";

let callbacks = new Set<{ ref: any; fn: () => void }>();

const handleClick = (e: any) => {
  callbacks.forEach(callback => {
    if (callback.ref.current && !callback.ref.current.contains(e.target)) {
      callback.fn();
    }
  });
};

let hasAdded = false;
const lazilyAddDocumentListener = () => {
  if (!hasAdded) {
    document.addEventListener("click", handleClick);
    hasAdded = true;
  }
};

export function useOnOutsideClick<T>(p: {
  callback: () => void;
  deps: any[]; //Change these when the callback should be updated
  enabled: boolean; //Whether outside clicks should even be detected
}) {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (p.enabled) {
      lazilyAddDocumentListener();
      const val = { ref, fn: p.callback };
      callbacks.add(val);

      return () => {
        callbacks.delete(val);
      };
    }
  }, [p.enabled, ...p.deps]);

  return ref;
}
